@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: red solid 0px;
}
table {
  border-radius: 5px !important;
  border-collapse: collapse !important;
  border: rgb(229, 231, 235) solid !important;
}
table th,
table td {
  border: rgb(229, 231, 235) solid !important
}
table tr:hover td {
  background-color: #ddd !important
}

.font-titan {
  font-family: 'Titan One', cursive;
}